<template>
    <div>
        <p class="text">
            {{ $t('faq.playberry.step2.toLogin') }} 
             (<span class="link">{{ playberryLink }}</span>)
        </p>

        <p class="text">
            https: (<span class="link">{{ playberryLink }}</span>)
        </p>

        <p class="text">{{ $t('faq.playberry.step2.addAsterics') }}</p>

        <p class="text">{{ $t('faq.playberry.step2.example') }}</p>
        <p class="text">{{ $t('faq.playberry.step2.username') }}</p>
        <p class="text">{{ $t('faq.playberry.step2.password') }}</p>

        <div class="linkContainer">
            <p class="text">{{ $t('faq.playberry.step2.linksLook') }}</p>
            <p class="link">{{ linksLook }}</p>
        </div>
        
        <div class="screenshot">
            <img src="../assets/screen-2.png" alt="Playberry" :width="277" class='image'>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['brandConfig']),
        playberryLink() {
            return `${this.brandConfig.tvLink}/api/list/USERNAME/PASSWORD`
        },
        linksLook() {
            return `${this.brandConfig.tvLink}/api/list/bob/123bb1`
        }
    }
}
</script>

<style scoped>
p,
ol {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.text {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #FFFFFF99;
}

.step-list {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    color: #FFFFFF99;
    padding: 0 16px;
    margin-bottom: 32px;
}

.linkContainer{
    margin-top: 15px;
    margin-bottom: 32px;
}

 .link{
    color: #918FFA;
    text-decoration: underline;
    word-wrap: break-word; 
}

.screenshot {
    display: flex;
    justify-content: center;
    padding: 32px 10px 0;
    border-radius: 8px;
    background-color: #141417;
}

@media only screen and (max-width: 1024px) {
    .image{
        width: 100%;
    }
}
</style>
